import { useMemo } from 'react';
import PromotionalTile from '../promotional-tile';
import { PromotionalTileProps } from '../promotional-tile/types';

export enum BentoType {
  '50-50' = '3-3',
  '33-33-33' = '2-2-2',
  '33-66' = '2-4',
  '66-33' = '4-2',
}

export interface PromotionalTileConfig extends PromotionalTileProps {
  ctaTitle?: string;
  ctaFormat: 'secondary' | 'textlink';
}

export interface BentoBoxProps {
  gridType: keyof typeof BentoType;
  promotionalTiles: PromotionalTileConfig[];
}

const BentoBox: React.FC<BentoBoxProps> = ({ gridType, promotionalTiles }) => {
  const childStyle = useMemo(() => {
    const gridConfig = BentoType[gridType];
    const columnSizes = gridConfig?.split('-');
    return columnSizes.map((size, index) => {
      const { ctaFormat, ctaTitle } = promotionalTiles?.[index] || {};
      const ctaStyle = ctaTitle ? { title: ctaTitle, style: ctaFormat } : undefined;
      return (
        <div key={index} className={`max-[743px]:w-full col-span-${size} min-h-[424px]`}>
          {promotionalTiles?.[index] && <PromotionalTile {...promotionalTiles[index]} ctaStyle={ctaStyle} />}
        </div>
      );
    });
  }, [gridType, promotionalTiles]);

  return (
    <div className="mx-auto flex h-full max-w-7xl grid-cols-6 flex-col gap-16 px-24 md:grid md:gap-18 lg:px-32">
      {childStyle}
    </div>
  );
};

export default BentoBox;
