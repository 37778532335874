import Slider from 'components/commercetools-ui/atoms/slider';
import Image, { ImageProps } from 'frontastic/lib/image';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useTouchDevice from 'helpers/hooks/useTouchDevice';
import { desktop, smallMobile, tablet } from 'helpers/utils/screensizes';
import { useMemo } from 'react';

export interface IncentiveItem {
  description: string;
  image: ImageProps;
  title: string;
}

export interface IncentivesProps {
  incentives: IncentiveItem[];
}

const Incentives: React.FC<IncentivesProps> = ({ incentives }) => {
  const [isTabletSize] = useMediaQuery(tablet);
  const { isTouchDevice } = useTouchDevice();

  const shouldShowArrows = useMemo(() => {
    return !isTabletSize || incentives.length > 3;
  }, [isTabletSize, incentives]);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <Slider
          dots={shouldShowArrows}
          className="!pb-40"
          solidArrows
          arrows={shouldShowArrows}
          allowArrowsOnTouchDevice
          allowTouchMove={isTouchDevice}
          nextButtonStyles={{ transform: 'translateY(-150%)', right: '10px' }}
          prevButtonStyles={{ transform: 'translateY(-150%)', left: '10px' }}
          slidesPerView={isTabletSize ? 3 : 1}
          breakpoints={{
            [smallMobile]: {
              spaceBetween: 0,
            },
            [tablet]: {
              spaceBetween: 18,
            },
            [desktop]: {
              spaceBetween: 32,
            },
          }}
        >
          {incentives.map((incentive) => (
            <div key={incentive.title} className="flex-col px-10 sm:flex md:px-0 lg:block">
              <div className="flex items-center justify-center py-40 sm:shrink-0">
                <Image
                  {...incentive.image}
                  priority
                  loading="eager"
                  alt={incentive.title}
                  style={{ objectFit: 'cover', objectPosition: 'center' }}
                  className="h-80 w-80"
                />
              </div>
              <div className="mt-4 text-center sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <div className="pb-10 font-bold">{incentive.title}</div>
                <p className="mt-2 text-sm">{incentive.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Incentives;
