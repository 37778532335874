import Markdown from 'components/commercetools-ui/organisms/markdown';
import { useAccount } from 'frontastic';
import Redirect from 'helpers/redirect';
import { useSearchParams } from 'next/navigation';
import { FC, useCallback, useEffect, useState } from 'react';
import { Order } from 'shared/types/cart/Order';
import { Payment } from 'shared/types/cart/Payment';
import { mutate } from 'swr';
import FeedbackIconLayer from '../../../../commercetools-ui/atoms/button/feedbackIconLayer';
import usePurchase from '../../hooks/usePurchase';
import useTrackPage from '../../hooks/useTrack';
import OrderSummary from './components/order-summary';
import ThankYouHeader from './components/thank-you-header';

export interface BillingAddress {
  name: string;
  billingStreetAddressLine1: string;
  billingStreetAddressLine2: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  billingCountryCode: string;
}

export type ThankYouContentProps = {
  moreInfo: string; // markdown content with more information
};

const ThankYouContent: FC<ThankYouContentProps> = ({ moreInfo }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState<Order>();
  const { trackPage } = useTrackPage();
  const { loggedIn } = useAccount();
  const { getOrderByOrderNumber, getPaymentDetail } = usePurchase();
  const [payment, setPayment] = useState<Payment>();

  const searchParams = useSearchParams();
  const orderNumber = searchParams.get('orderNumber')?.trim() || '';

  const getSetCommerceToolsOrder = useCallback(async () => {
    if (!orderNumber) return;
    let orderObject: Order | undefined;

    /**
     * Wait for the order to be created and the payment to be attached to it
     */
    for (let i = 0; i < 5; i++) {
      orderObject = await getOrderByOrderNumber(orderNumber);
      if (orderObject?.payments?.[0]?.id) {
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    if (orderObject) {
      setOrder(orderObject);
      trackPage(orderObject);
    }
    if (orderObject?.payments?.[0].paymentProvider === 'STRIPE') {
      const paymentDetail = await getPaymentDetail(orderObject.payments[0].id);
      setPayment(paymentDetail);
    }
    await mutate('/action/cart/getCart');
    setIsLoading(false);
  }, [getOrderByOrderNumber, orderNumber, trackPage]);

  useEffect(() => {
    getSetCommerceToolsOrder();
  }, [getSetCommerceToolsOrder]);

  if (!loggedIn || !orderNumber) return <Redirect target="/" />;

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {isLoading ? (
        <div className="flex h-200 items-center justify-center">
          <FeedbackIconLayer loading={isLoading} variant="ghost" />
        </div>
      ) : (
        <div className="mx-auto max-w-7xl px-24 lg:px-32">
          <div className="mx-auto lg:mx-0 lg:max-w-none">
            <div className="bg-white lg:rounded-md lg:pt-36">
              <ThankYouHeader email={order?.email} onPrint={handlePrint} />
              {moreInfo && (
                <Markdown
                  className="prose mx-auto block max-w-7xl py-24 pb-12 pt-24  lg:rounded-md lg:py-36 "
                  markdown={moreInfo}
                />
              )}
              {order && <OrderSummary order={order} payment={payment} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ThankYouContent;
