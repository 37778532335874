'use client';

import AnnouncementBanner, { AnnouncementBannerProps } from 'components/padi-ui/announcement-banner';
import { TasticProps } from '../../types';
import { Product } from 'shared/types/product/Product';
import { DataSource } from 'types/datasource';

const getDataAnalyticsFromProduct = (product: Product | undefined, defaultValue = ''): string => {
  const dataAnalyticsConfig = product?.variants?.[0]?.attributes?.['section-15'];
  if (dataAnalyticsConfig) {
    try {
      return JSON.parse(dataAnalyticsConfig)?.['padi-analytics'] || defaultValue;
    } catch (e) {
      console.error('Error parsing data analytics config', (e as Error).message);
    }
  }
  return defaultValue;
};

const AnnouncementBannerTastic = ({
  data,
}: TasticProps<DataSource<{ product: Product }> & AnnouncementBannerProps>) => {
  const dataAnalytics = getDataAnalyticsFromProduct(data?.data?.dataSource?.product, data.dataAnalytics);
  return <AnnouncementBanner {...data} dataAnalytics={dataAnalytics} />;
};

export default AnnouncementBannerTastic;
