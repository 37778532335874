'use client';

import CardComparison, { CardComparisonProps } from 'components/padi-ui/card-comparison';
import { TasticProps } from 'frontastic/tastics/types';

const CardComparisonTastic = ({ data }: TasticProps<CardComparisonProps>) => {
  return <CardComparison {...data} />;
};

export default CardComparisonTastic;
