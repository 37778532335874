'use client';

import { useSubscription } from '@apollo/client';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import { SUBSCRIPTION_SLO_DELETE_SESSION } from 'helpers/padi/slo/gql';
import { useAccount } from 'frontastic';
import { DeleteSloSession } from '../index';
import { deleteCognitoCookies } from 'helpers/padi/cognito';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { datadogLogs } from '@datadog/browser-logs';
import { DataDogCustomLog } from 'shared/types/logs/datadog';

interface Props {}
const SloSubscribeOnDelete: React.FC<Props> = () => {
  const { userData } = useGetUserInfo();
  const sub = userData?.sub;
  const { account, logout } = useAccount();
  const pathname = usePathname();
  const router = useRouter();
  const { locale } = useParams();
  const { data, loading, error } = useSubscription(SUBSCRIPTION_SLO_DELETE_SESSION, {
    variables: { sub },
  });

  if (sub) {
    const ddLogPayload: DataDogCustomLog = {
      data: {
        affilateId: userData?.['custom:affiliate_id'],
        customerId: account?.accountId,
      },
    };

    if (data) {
      console.log('---- slo data: ', data);
      logout()
        .then(() => {
          try {
            DeleteSloSession(sub);
            datadogLogs.logger.info('SLO session deleted', ddLogPayload);
          } catch (err) {
            ddLogPayload.data.error = err;
            datadogLogs.logger.info('SLO session Error', ddLogPayload);
          }
        })
        .then(() => deleteCognitoCookies())
        .then(() => {
          Object.keys(localStorage)
            .filter((key) => !key.startsWith('padi'))
            .map((key) => localStorage.removeItem(key));
        })
        .then(() => (pathname.endsWith('courses/') ? window.location.reload() : router.push(`/${locale}/courses`)));
    }

    if (error) {
      console.error('----- DeleteSloSession : ', error);
    }
  }

  return <></>;
};

export default SloSubscribeOnDelete;
