'use client';

import MenuTabs, { MenuTabsProps } from 'components/padi-ui/menu-tabs';
import { TasticProps } from 'frontastic/tastics/types';
import { memo } from 'react';

const MenuTabsTastic = ({ data }: TasticProps<MenuTabsProps>) => {
  return <MenuTabs {...data} />;
};

export default memo(MenuTabsTastic);
