import Link from 'components/commercetools-ui/atoms/link';
import React, { useMemo, useEffect } from 'react';
import { BreadcrumbItem } from './types';
import { useParams } from 'next/navigation';
import { Reference } from 'types/reference';

export interface BreadcrumbsUIProps {
  items: BreadcrumbItem[];
}

const BreadcrumbsUI: React.FC<BreadcrumbsUIProps> = ({ items }) => {
  const { locale } = useParams();
  const itemLink = useMemo((): (Reference | null)[] => {
    return items.map((item) => {
      return typeof item.url === 'string'
        ? {
            link: /^http/.test(item.url) ? item.url : `${location.origin}/${locale}/${item.url}`,
            type: 'link',
          }
        : null;
    });
  }, [items, locale]);

  const breadcrumbSchema = useMemo(() => {
    const schema = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: items.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.label,
        item: /^http/.test(item.url) ? item.url : `${location.origin}/${locale}/${item.url}`,
      })),
    };
    return JSON.stringify(schema);
  }, [items, locale]);

  useEffect(() => {
    const tag = document.createElement('script');
    tag.type = 'application/ld+json';
    tag.innerHTML = breadcrumbSchema;
    document.head.appendChild(tag);
    return () => {
      document.head.removeChild(tag);
    };
  }, [breadcrumbSchema]);

  return (
    <nav aria-label="Breadcrumb" className="mx-auto flex max-w-7xl px-24 pb-32 pt-20 sm:pt-24 lg:px-32 lg:pt-32">
      <ol role="list" className="flex items-center space-x-4">
        {items.map((item, index) => (
          <li key={item.label}>
            <div className="items-center">
              {index > 0 && <span className="shrink-0 px-8 text-14 text-gray-300">/</span>}
              {itemLink[index] ? (
                <Link link={itemLink[index]}>
                  <span className="w-400 text-14 text-padi-blue">{item.label}</span>
                </Link>
              ) : (
                <span className="w-400 text-14 text-padi-gray-darkest">{item.label}</span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadcrumbsUI;
