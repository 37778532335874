import { useMemo } from 'react';
import BreadcrumbsUI from './breadcrumbs-ui';
import type { BreadcrumbItem } from './types';
import { Product } from 'shared/types/product/Product';

const DynamicPageBreadcrumbs: React.FC<{ items: BreadcrumbItem[]; product?: Product }> = ({ items, product }) => {
  const convertItems = useMemo(() => {
    const breadcrumbLinks = product ? [...items, { label: product.name || '', url: location.href }] : items;

    const _items = breadcrumbLinks.map((item) => {
      return {
        ...item,
        url: (item.url || '').replace(/^\//, ''),
      };
    });

    _items.unshift({ label: 'Home', url: 'https://www.padi.com/' });

    return _items;
  }, [items, product]);

  return <BreadcrumbsUI items={convertItems} />;
};

export default DynamicPageBreadcrumbs;
