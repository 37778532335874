'use client';

import VideoPlayer, { VideoPlayerProps } from 'components/padi-ui/video-player';
import { TasticProps } from 'frontastic/tastics/types';

const VideoPlayerTastic = ({ data }: TasticProps<VideoPlayerProps>) => {
  return <VideoPlayer {...data} />;
};

export default VideoPlayerTastic;
