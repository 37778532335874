import React from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

interface RatingIconProps {
  rating: number;
  ratingDescription?: string;
  className?: string;
}

const RatingIcon: React.FC<RatingIconProps> = ({ rating, ratingDescription, className }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  return (
    <div className={`flex items-center gap-13 ${className}`}>
      <div className="flex items-center gap-5">
        {Array.from({ length: fullStars }, (_, index) => (
          <FaStar key={index} size={16} color="#0070D3" />
        ))}
        {hasHalfStar && <FaStarHalfAlt size={16} color="#0070D3" />}
      </div>
      {ratingDescription && <span className="text-14 text-padi-gray-darkest">{ratingDescription}</span>}
    </div>
  );
};

export default RatingIcon;
