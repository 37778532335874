import Markdown from 'components/commercetools-ui/organisms/markdown';
import { useMemo } from 'react';
import TestimonialItem, { TestimonialItemProps } from './testimonial-item';

export interface TestimonialsProps {
  testimonials: TestimonialItemProps[];
  bgColor: string;
  bodyText: string;
}

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials, bgColor, bodyText }) => {
  const styles = useMemo(() => {
    if (bgColor === 'padi-gray-darkest') {
      return {
        textColor: 'text-white',
        textColorTitle: 'text-white',
        lineColor: 'border-white',
      };
    }
    return {
      textColor: 'text-padi-gray-darkest',
      textColorTitle: 'text-padi-gray-darker',
      lineColor: 'border-gray-900/10',
    };
  }, [bgColor]);

  const testimonialLayouts = useMemo(
    () => [
      'flex pt-0 pb-40 lg:p-60 flex-col',
      `flex pt-40 lg:p-60 flex-col border-t ${styles.lineColor}  lg:border-l lg:border-t-0`,
    ],
    [styles.lineColor],
  );

  return (
    <section className="mx-auto max-w-7xl px-24 lg:px-32">
      <div className={`px-24 lg:px-0 bg-${bgColor} rounded-lg py-60  ${styles.textColor}`}>
        <div className="mx-auto grid max-w-2xl grid-cols-1 text-center lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:text-left">
          {testimonials.map((item, index) => (
            <div key={index} className={testimonialLayouts[index]}>
              <TestimonialItem
                logo={item.logo}
                textColorTitle={styles.textColorTitle}
                body={item.body}
                name={item.name}
                title={item.title}
                avatar={item.avatar}
              />
            </div>
          ))}
        </div>
        {bodyText && (
          <div
            className={`mx-auto mt-40 max-w-2xl border-t pt-40 text-center lg:border-t-0 lg:pt-0 ${styles.lineColor}`}
          >
            <Markdown className="text-base" markdown={bodyText} />
          </div>
        )}
      </div>
    </section>
  );
};

export default Testimonials;
