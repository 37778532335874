import type { JSX } from 'react';
import { ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import Button from 'components/commercetools-ui/atoms/button';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import Image from 'frontastic/lib/image';

export interface CtaSectionProps {
  image: {
    media: {
      alt: string;
      file: string;
    };
    title: string;
  };
  eyebrow: string;
  title: string;
  titleHtag?: string;
  subtitle: string;
  body: string;
  list: Record<string, any>;
  ctaLabel: string;
  ctaLink: {
    link: string;
    openInNewWindow: boolean;
    type: string;
  };
  bgColor: string;
  isButton?: boolean;
  buttonVariant?: 'primary' | 'secondary';
  imagePosition?: 'left' | 'right';
}

const CtaSection: React.FC<CtaSectionProps> = ({
  image,
  eyebrow,
  title,
  titleHtag,
  subtitle,
  body,
  list,
  ctaLabel,
  ctaLink,
  bgColor,
  isButton = false,
  buttonVariant = 'primary',
  imagePosition = 'left',
}) => {
  const isHtml = isStringHtml(body);
  const imageSrc = image?.media?.file;
  const imageAlt = image?.media?.alt;
  const imageTitle = image?.title;
  const ctaLinkUrl = ctaLink?.link;
  const TitleHtag = titleHtag ? (titleHtag as keyof JSX.IntrinsicElements) : 'h2';
  const bgColorClass = `bg-${bgColor}`;

  return (
    <div className="relative isolate">
      <div className={`${bgColorClass} mx-auto max-w-7xl px-24 lg:px-32`}>
        <div
          className={`mx-auto flex max-w-2xl flex-col gap-32 p-24 md:p-32 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:p-64 ${
            imagePosition === 'right' ? 'lg:flex-row-reverse' : ''
          }`}
        >
          <div className="w-full flex-none lg:max-w-sm">
            <Image
              className="aspect-square w-full rounded-md"
              src={imageSrc}
              alt={imageAlt}
              title={imageTitle}
              style={{ objectFit: 'cover' }}
            />
          </div>

          <div className="w-full flex-auto">
            <p className="text-base font-semibold leading-7 text-slate-950">{eyebrow}</p>
            <TitleHtag className="mt-20 text-3xl font-bold tracking-tight">{title}</TitleHtag>
            <div className="mt-20 text-base font-bold tracking-tight">{subtitle}</div>
            <div className="prose mt-6 text-base leading-8">
              {isHtml ? <div dangerouslySetInnerHTML={{ __html: body }} /> : <Markdown markdown={body} />}
            </div>
            <ul role="list" className="mt-10 space-y-16 text-base leading-7">
              {Object.values(list).map((listItem, index) => (
                <li key={index} className="flex gap-x-12">
                  <CheckCircleIcon className="h-28 w-20 flex-none text-padi-blue" aria-hidden="true" />
                  {isStringHtml(listItem?.item) ? (
                    <div dangerouslySetInnerHTML={{ __html: listItem?.item }} />
                  ) : (
                    <Markdown markdown={listItem?.item} />
                  )}
                </li>
              ))}
            </ul>
            {ctaLinkUrl && (
              <div className="mt-40 flex">
                {isButton ? (
                  <Button
                    variant={buttonVariant}
                    size="s"
                    onClick={() => window.open(ctaLink.link, ctaLink.openInNewWindow ? '_blank' : '_self')}
                    className="w-full items-center bg-padi-blue lg:w-auto"
                  >
                    {ctaLabel}
                  </Button>
                ) : (
                  <a href={ctaLink.link} className="flex text-sm font-semibold leading-6 text-padi-blue">
                    {ctaLabel}
                    <ArrowRightIcon className="h-28 w-20 flex-none text-padi-blue" aria-hidden="true" />
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaSection;
