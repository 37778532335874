'use client';

import Breadcrumbs, { BreadcrumbsProps } from 'components/padi-ui/breadcrumbs';
import { TasticProps } from 'frontastic/tastics/types';
import { Product } from 'shared/types/product/Product';
import { DataSource } from 'types/datasource';

const BreadcrumbsTastic = ({ data }: TasticProps<DataSource<{ product: Product }> & BreadcrumbsProps>) => {
  const { data: productData, ...props } = data;
  return <Breadcrumbs {...props} product={productData?.dataSource?.product} />;
};

export default BreadcrumbsTastic;
