import React from 'react';

type Props = {
  className?: string;
  stroke?: string;
  fill?: string;
};

const Icon: React.FC<Props> = ({ className, stroke, fill }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
    <path
      d="M22.8963 20.8948C20.5273 21.3885 19.0491 19.0195 19.0491 19.0195C18.6824 19.6021 18.1771 20.0849 17.5784 20.4247C16.9796 20.7645 16.3061 20.9507 15.6178 20.9668C14.9335 20.9341 14.2665 20.7408 13.6707 20.4025C13.075 20.0642 12.5672 19.5904 12.1885 19.0195C11.8218 19.602 11.3167 20.0847 10.7182 20.4245C10.1196 20.7642 9.44624 20.9506 8.75816 20.9668C8.07355 20.9348 7.40615 20.7417 6.81014 20.4034C6.21412 20.065 5.70628 19.5909 5.32785 19.0195C5.32785 19.0195 3.42507 21.4236 1.10156 20.9336M22.8963 17.1044C20.5273 17.5981 19.0491 15.2291 19.0491 15.2291C18.6824 15.8117 18.1771 16.2945 17.5784 16.6343C16.9796 16.974 16.3061 17.1603 15.6178 17.1764C14.9335 17.1437 14.2665 16.9503 13.6707 16.612C13.075 16.2737 12.5672 15.8 12.1885 15.2291C11.8218 15.8116 11.3167 16.2942 10.7182 16.634C10.1196 16.9738 9.44624 17.1601 8.75816 17.1764C8.07355 17.1443 7.40615 16.9513 6.81014 16.6129C6.21412 16.2746 5.70628 15.8005 5.32785 15.2291C5.32785 15.2291 3.42507 17.6331 1.10156 17.1432"
      stroke="#002547"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.15625 14.5317V3.369C9.15625 2.7407 9.40584 2.13814 9.85011 1.69386C10.2944 1.24959 10.8969 1 11.5252 1C12.1535 1 12.7561 1.24959 13.2004 1.69386C13.6447 2.13814 13.8942 2.7407 13.8942 3.369V4.3166"
      stroke="#002547"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.7891 14.7354V3.369C15.7891 2.7407 16.0387 2.13814 16.4829 1.69386C16.9272 1.24959 17.5298 1 18.1581 1C18.7864 1 19.3889 1.24959 19.8332 1.69386C20.2775 2.13814 20.5271 2.7407 20.5271 3.369V4.3166"
      stroke="#002547"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.1543 9.52838H15.7875"
      stroke="#002547"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.1543 6.68567H15.7875"
      stroke="#002547"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.1543 12.3712H15.7875"
      stroke="#002547"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Icon;
