import Markdown from 'components/commercetools-ui/organisms/markdown';
import Image, { ImageProps } from 'frontastic/lib/image';
import { useMemo } from 'react';

export interface TestimonialItemProps {
  logo: ImageProps;
  body: string;
  name?: string;
  title?: string;
  avatar?: ImageProps;
  textColorTitle?: string;
}

const TestimonialItem: React.FC<TestimonialItemProps> = ({ logo, body, name, title, avatar, textColorTitle }) => {
  const isDisplayCaption = useMemo(() => {
    return avatar?.media || name || title;
  }, [avatar, name, title]);

  return (
    <>
      {logo?.media && <Image {...logo} className="mb-44 h-48 w-auto self-start" />}
      <figure className="flex flex-auto flex-col justify-between">
        <blockquote className="text-lg leading-8 md:text-3xl">
          <Markdown markdown={body} />
        </blockquote>
        {isDisplayCaption && (
          <figcaption className="mt-44 flex items-center gap-x-27">
            {avatar?.media && <Image {...avatar} className="h-57 w-57 rounded-full bg-gray-50" />}
            <div className="text-base">
              <div className="font-semibold">{name}</div>
              <div className={`${textColorTitle} mt-4`}>{title}</div>
            </div>
          </figcaption>
        )}
      </figure>
    </>
  );
};

export default TestimonialItem;
