import type { JSX } from 'react';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { isStringHtml } from 'helpers/utils/isStringHtml';

export interface Feature {
  icon: string;
  title: string;
  description: string;
  ctaUrl?: string;
  ctaLabel?: string;
}

export interface FeatureSectionDynamicListProps {
  title: string;
  titleHtag?: string;
  bgColor: string;
  features: Feature[];
}

const FeatureSectionDynamicList: React.FC<FeatureSectionDynamicListProps> = ({
  title,
  titleHtag,
  bgColor,
  features,
}) => {
  const classNameMain = `bg-${bgColor} pb-46 pt-34 lg:pt-64`;
  const TitleHtag = titleHtag ? (titleHtag as keyof JSX.IntrinsicElements) : 'h2';

  return (
    <div className={classNameMain}>
      <div className="mx-auto max-w-7xl px-24 lg:px-32">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <TitleHtag className="text-2xl font-medium">{title}</TitleHtag>
        </div>
        <div className="mx-auto mt-34 max-w-2xl lg:mt-62 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-32 gap-y-22 lg:max-w-none lg:grid-cols-3 lg:gap-y-52">
            {features.map((feature, index) => (
              <div key={index} className="flex flex-col">
                <dt className="flex items-center gap-x-10 font-semibold">
                  <span className="material-symbols-outlined" aria-hidden="true">
                    {feature.icon}
                  </span>
                  {feature.title}
                </dt>
                <dd className="mt-0 flex flex-auto flex-col text-base leading-7 text-padi-gray-darker">
                  <div className="prose max-w-none">
                    {isStringHtml(feature.description) ? (
                      <div dangerouslySetInnerHTML={{ __html: feature.description }} />
                    ) : (
                      <Markdown className="flex-auto" markdown={feature.description} />
                    )}
                  </div>
                  {feature.ctaUrl && (
                    <p className="mt-6">
                      <a
                        href={feature.ctaUrl}
                        className="text-sm font-semibold leading-5 text-padi-blue hover:text-padi-blue-darker"
                      >
                        {feature.ctaLabel} <span aria-hidden="true">→</span>
                      </a>
                    </p>
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FeatureSectionDynamicList;
