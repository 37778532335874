import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams, useParams, useRouter } from 'next/navigation';
import { jwtDecode } from 'jwt-decode';
import { Address } from 'shared/types/account/Address';
import countries from 'shared/types/countries.json';
import { Product, Variant } from 'shared/types/product';
import FeedbackIconLayer from 'components/commercetools-ui/atoms/button/feedbackIconLayer';
import { ErrorDisplay } from 'helpers/errorDisplay';
import { useFormat } from 'helpers/hooks/useFormat';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import usePath from 'helpers/hooks/usePath';
import { getLocalStorage } from 'helpers/utils/localStorage';
import { sdk } from 'sdk';
import { useAccount, useCart } from 'frontastic';
import PadiGeolocationModal from '../../padi-geolocation-modal';
import usePurchase from '../checkout/hooks/usePurchase';

export interface SSOToken {
  sub: string;
  'custom:affiliate_id': string;
}

const ReferralRedeem: React.FC = ({}) => {
  const router = useRouter();
  const { path } = usePath();
  const { locale } = useParams();
  const searchParams = useSearchParams();
  const referralId = searchParams.get('referralId')?.trim() || '';
  const { addItem, data: cart } = useCart();
  const { createStripeCheckoutSession } = usePurchase();
  const { account, verifyLoggedIn, getProjectConfig } = useAccount();
  const { getUserEntitlments } = useGetUserInfo();
  const [redeemed, setRedeemed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [localeStored, setLocaleStored] = useState<boolean>(false);
  const referralSKU = 'CLUBRAF30';
  const { formatMessage: formatMessagePadi } = useFormat({ name: 'padi' });

  const userLocale = getLocalStorage('padiUserLocale');
  useEffect(() => {
    const checkLogin = async () => {
      const isLoggedIn = await verifyLoggedIn();
      if (!isLoggedIn) {
        let lvp = `${path}?referralId=${referralId}`;
        if (path && path.startsWith('/')) {
          lvp = lvp.substring(1);
        }
        router.push(`/login?lvp=${lvp}`);
      } else {
        setLocaleStored(true);
      }
    };

    if (userLocale === locale) {
      const referralId = searchParams.get('referralId')?.trim() || '';
      if (referralId) {
        checkLogin();
      } else {
        router.push('/');
      }
    }
  }, [userLocale, locale, isOpened]);

  useEffect(() => {
    if (redeemed || !cart || !localeStored || !referralId) return;

    const redeemReferral = async (token: string) => {
      const variant: Variant = {
        sku: referralSKU,
      };
      try {
        const payload = await getAddress(token);
        await sdk.callAction({
          actionName: 'cart/updateCartWithAddress',
          payload,
        });
        await addItem({} as Product, variant, 1);
        setRedeemed(true);
      } catch {
        router.push('/');
      }
    };

    const validateClubUserAndRedeem = async (idToken: string, sub: string) => {
      // Check if a customer has already redeemed a Club Trial now over
      const userEntitlements = await getUserEntitlments(sub);
      if (userEntitlements?.customerStatus?.entitlementStatus === 'EXPIRED') {
        ErrorDisplay.setAlert({
          alertType: 'warning',
          body: `${formatMessagePadi({
            id: 'trial.cart.alert',
            defaultMessage:
              'You’ve already redeemed your Club Trial. Please purchase a Club Membership to regain access to Club Benefits.',
          })}`,
        });
        router.push('/courses');
      } else if (userEntitlements?.customerStatus?.entitlementStatus === 'ACTIVE') {
        ErrorDisplay.setAlert({
          alertType: 'warning',
          body: `${formatMessagePadi({
            id: 'subscription.cart.alert',
            defaultMessage:
              'Our records show you already have a PADI Club Membership. Access your benefits and manage your subscription on the Club Benefits page.',
          })}`,
        });
        router.push('/courses');
      } else {
        setIsProcessing(true);
        if (!isProcessing) {
          redeemReferral(idToken || '');
        }
      }
    };

    const idToken = window.localStorage.getItem('idToken');
    const user = jwtDecode<SSOToken>(idToken || '');
    const sub = user?.sub;
    if (!idToken || !sub || referralId !== sub) {
      router.push('/courses');
    } else {
      const hasNoTrial =
        !cart?.lineItems || cart.lineItems.length === 0 || cart.lineItems.every((i) => i.variant?.sku !== referralSKU);
      if (hasNoTrial) {
        // Allow checkout if a customer has not already redeemed a Club Trial now over
        validateClubUserAndRedeem(idToken, sub);
      }
    }
  }, [cart, localeStored, redeemed]);

  useEffect(() => {
    if (!cart || !cart.lineItems || cart.lineItems.length === 0 || !redeemed) return;

    const createStripeSession = async () => {
      const url = await createStripeCheckoutSession();
      if (url) router.push(url);
    };

    if (cart.lineItems.length > 1 || cart.lineItems.some((i) => i.variant?.sku !== referralSKU)) {
      router.push('/cart');
    } else {
      createStripeSession();
    }
  }, [redeemed, cart]);

  const getAddress = useCallback(async (token: string) => {
    const countryCode = getLocalStorage('padiUserCountry')?.toUpperCase() || 'US';
    const country = countries.find((c) => c.twoLetterISORegionName === countryCode);
    let address: Address = {
      firstName: account?.firstName,
      lastName: account?.lastName,
      country: country && country.mappingCode ? country.mappingCode : countryCode,
    };
    const user = jwtDecode<SSOToken>(token || '');
    const affiliateId = user['custom:affiliate_id'] || '';
    const contactEndpoint = await getProjectConfig('EXTENSION_PADI_APIS_CONTACT_ENDPOINT');
    const contactRes = await fetch(`${contactEndpoint.setting}/${affiliateId}/addresses?addressType=Shipping`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (contactRes.ok) {
      const contactAddress = await contactRes.json();
      if (contactAddress && contactAddress.length > 0) {
        const { addressLine1, addressLine2, postalCode, city, stateProvinceIsoCode, countryIsoCode } =
          contactAddress[0];
        address = {
          streetName: addressLine1,
          additionalStreetInfo: addressLine2,
          postalCode: postalCode,
          city: city,
          state: stateProvinceIsoCode,
          country: countryIsoCode,
          ...address,
        };
      }
    }
    const profileEndpoint = await getProjectConfig('EXTENSION_PADI_APIS_LEARNING_PROFILE_ENDPOINT');
    const profileRes = await fetch(`${profileEndpoint.setting}/${affiliateId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (profileRes.ok) {
      const profile = await profileRes.json();
      address.phone = profile.phoneNumber;
    }
    const additionalAddressInfo = JSON.stringify({
      ...address,
      email: account?.email,
      taxAddress: {
        countryName: country?.englishName,
        countryId: country?.id,
      },
    });
    address.additionalAddressInfo = additionalAddressInfo;
    return {
      email: account?.email,
      affiliateId,
      storeNumber: getLocalStorage('affiliateDiveShop') || '',
      billing: address,
      shipping: {
        state: address.state,
        country: address.country,
        additionalAddressInfo,
      },
    };
  }, []);

  const syncIsOpen = (open: boolean) => {
    setIsOpened(true);
  };

  return (
    <>
      <PadiGeolocationModal syncIsOpen={syncIsOpen} />
      <FeedbackIconLayer loading={true} variant="secondary" />
    </>
  );
};

export default ReferralRedeem;
