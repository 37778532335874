import React from 'react';
import HeaderLogo from 'components/padi-ui/header/header-logo';
import { PadiHeaderProps } from 'components/padi-ui/header/types';
import UserInfo from './user';

export type PadiSimplifiedHeaderProps = Pick<PadiHeaderProps, 'logo' | 'logoLink'>;

const PadiSimplifiedHeader: React.FC<PadiSimplifiedHeaderProps> = ({ logo, logoLink }) => {
  return (
    <>
      <header className="relative h-52 w-full bg-white lg:h-64">
        <div
          aria-label="Top"
          className="mx-auto flex h-full max-w-7xl items-center justify-between px-16 sm:px-24 lg:px-32"
        >
          <div className="flex h-full w-full items-center justify-start">
            <HeaderLogo
              logo={logo}
              logoLink={logoLink}
              imageClassName="flex h-full w-116 justify-start text-16 font-bold md:text-28"
            />
          </div>
          <UserInfo />
        </div>
      </header>
    </>
  );
};
export default React.memo(PadiSimplifiedHeader);
