import { memo } from 'react';
import HeaderSection, { HeaderSectionProps } from '../header-section';
import Rating from '../rating';

export interface HeaderStarProps {
  rating: number;
  ratingDescription: string;
  textGuide: string;
  headerSectionProps: HeaderSectionProps;
}

const HeaderStar: React.FC<HeaderStarProps> = ({ rating, ratingDescription, textGuide, headerSectionProps }) => {
  const { bgColor, textAlign } = headerSectionProps;
  const classNameMain = `bg-${bgColor} py-24 sm:py-32 text-${textAlign}`;
  return (
    <div className={classNameMain}>
      <Rating rating={rating} ratingDescription={ratingDescription} className={`justify-${textAlign}`} />
      <p className="pt-8 text-base font-medium text-padi-gray-darkest">{textGuide}</p>
      <HeaderSection {...headerSectionProps} className="pt-8" />
    </div>
  );
};

export default memo(HeaderStar);
