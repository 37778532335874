'use client';

import HeroDestinationSearch, { HeroDestinationSearchProps } from 'components/padi-ui/hero-destination-search';
import { TasticProps } from 'frontastic/tastics/types';

const HeroDestinationSearchTastic = ({ data }: TasticProps<HeroDestinationSearchProps>) => {
  return <HeroDestinationSearch {...data} />;
};

export default HeroDestinationSearchTastic;
