import { useParams } from 'next/navigation';
import { useEffect } from 'react';

export interface CrowdRiffProps {
  crowdRiffId?: string;
}

const CrowdRiff: React.FC<CrowdRiffProps> = ({ crowdRiffId }) => {
  const { locale } = useParams();
  const langCode = locale.split('-');

  useEffect(() => {
    if (!crowdRiffId) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://starling.crowdriff.com/js/crowdriff.js';
    script.id = crowdRiffId;
    script.lang = langCode[0];
    script.async = true;
    const crowdRiff = document.querySelector('#crowdriff');
    crowdRiff?.appendChild(script);
  }, [crowdRiffId]);

  return <div id="crowdriff"></div>;
};

export default CrowdRiff;
