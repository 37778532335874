import React from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import { resolveReferenceTarget } from 'helpers/reference';
import { Reference } from 'types/reference';
import Image, { ImageProps } from 'frontastic/lib/image';

export interface FooterLink {
  name: string;
  reference: Reference;
  dataAnalytics?: string;
}

export interface FooterColumn {
  header?: string;
  links?: FooterLink[];
}

export interface Props {
  columns: FooterColumn[];
  socialMedia?: SocialMedia[];
  newsletterHeader?: string;
  newsletterDescription?: string;
  newsletterAction?: string;
  newsletterReference?: Reference;
  newsletterDataAnalytics?: string;
  bottomLinks?: FooterLink[];
}

export interface SocialMedia {
  name: string;
  logo: ImageProps;
  reference: Reference;
  dataAnalytics?: string;
}

const Footer: React.FC<Props> = ({
  columns,
  newsletterHeader,
  newsletterDescription,
  newsletterReference,
  newsletterAction,
  newsletterDataAnalytics,
  socialMedia,
  bottomLinks,
}) => {
  const copyright = '© PADI ' + new Date().getFullYear();
  return (
    <footer className="footer bg-padi-footer" aria-labelledby="footer-heading">
      <div className="mx-auto max-w-7xl px-24 pb-32 pt-20 sm:pt-24 lg:px-32 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-32">
          <div className="grid grid-cols-2 gap-32 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-32">
              <div>
                <h4 className="text-sm font-semibold leading-6 text-white">{columns[0].header}</h4>
                <ul role="list" className="mt-24 space-y-16">
                  {columns[0].links?.map((item, index) => (
                    <li key={`${index}-${item.name}`}>
                      <a
                        href={resolveReferenceTarget(item.reference)}
                        data-analytics={item.dataAnalytics}
                        className="text-sm leading-6 text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-40 md:mt-0">
                <h4 className="text-sm font-semibold leading-6 text-white">{columns[1].header}</h4>
                <ul role="list" className="mt-24 space-y-16">
                  {columns[1].links?.map((item, index) => (
                    <li key={`${index}-${item.name}`}>
                      <a
                        href={resolveReferenceTarget(item.reference)}
                        data-analytics={item.dataAnalytics}
                        className="text-sm leading-6 text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-32">
              <div>
                <h4 className="text-sm font-semibold leading-6 text-white">{columns[2].header}</h4>
                <ul role="list" className="mt-24 space-y-16">
                  {columns[2].links?.map((item, index) => (
                    <li key={`${index}-${item.name}`}>
                      <a
                        href={resolveReferenceTarget(item.reference)}
                        data-analytics={item.dataAnalytics}
                        className="text-sm leading-6 text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-40 md:mt-0">
                <h4 className="text-sm font-semibold leading-6 text-white">{columns[3].header}</h4>
                <ul role="list" className="mt-24 space-y-16">
                  {columns[3].links?.map((item, index) => (
                    <li key={`${index}-${item.name}`}>
                      <a
                        href={resolveReferenceTarget(item.reference)}
                        data-analytics={item.dataAnalytics}
                        className="text-sm leading-6 text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-40 xl:mt-0">
            <h4 className="text-sm font-semibold leading-6 text-white">{newsletterHeader}</h4>
            <p className="mt-32 text-sm leading-6 text-white">{newsletterDescription}</p>
            <div className="mt-10 sm:shrink-0">
              <Link link={newsletterReference} data-analytics={newsletterDataAnalytics}>
                <Button variant="primary" className="flex w-full items-center justify-center">
                  {newsletterAction}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="my-32 flex items-center justify-center">
          <img
            className="h-33 w-228"
            src="https://res.cloudinary.com/padi/image/upload/q_auto/v1583178660/global/PADI_Logo_SASO_RGB_28.svg"
          />
        </div>
        <div className="flex flex-col items-center justify-between gap-24 border-t border-white/10 pt-24 md:flex-row">
          <div className="flex flex-row flex-wrap items-center justify-center space-x-24 md:flex-row">
            <span className=" text-xs leading-5 text-white md:mt-0">{copyright}</span>
            {bottomLinks?.map((item, index) => (
              <>
                {item.reference && (
                  <a
                    href={resolveReferenceTarget(item.reference)}
                    className="text-xs leading-5 text-white"
                    key={`${index}-${item.name}`}
                    data-analytics={item.dataAnalytics}
                  >
                    <span> {item.name} </span>
                  </a>
                )}
              </>
            ))}
            <span id="teconsent" className="text-xs leading-5 text-white"></span>
          </div>
          <div className="flex space-x-24">
            {socialMedia?.map((item, index) => (
              <a
                key={`${index}-${item.name}`}
                href={resolveReferenceTarget(item.reference || {})}
                className="text-gray-500 hover:text-gray-400"
                data-analytics={item.dataAnalytics}
              >
                <Image {...item.logo} className="h-24 w-24" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div id="consent_blackbar"></div>
    </footer>
  );
};

export default Footer;
