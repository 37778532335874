'use client';

import Testimonials, { TestimonialsProps } from 'components/padi-ui/testimonials';
import { TasticProps } from 'frontastic/tastics/types';

const TestimonialsTastic = ({ data }: TasticProps<TestimonialsProps>) => {
  return <Testimonials {...data} />;
};

export default TestimonialsTastic;
