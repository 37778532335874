import React, { useCallback } from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import { UserIcon as UserIconSolid } from '@heroicons/react/24/solid';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import { Popover } from '@headlessui/react';
import { useAccount } from 'frontastic';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { deleteCognitoCookies } from 'helpers/padi/cognito';

const User: React.FC = () => {
  const { avatar, userData } = useGetUserInfo();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { logout, loggedIn } = useAccount();
  const router = useRouter();
  const pathname = usePathname();
  const { locale } = useParams();

  const handleLogout = useCallback(() => {
    logout()
      .then(() => deleteCognitoCookies())
      .then(() => (pathname.endsWith('courses/') ? window.location.reload() : router.push(`/${locale}/courses`)));
  }, [locale, pathname, logout]);

  return (
    <div className="flex w-full justify-end xl:w-fit">
      {loggedIn ? (
        <>
          <Popover className="relative  block h-fit">
            <Popover.Button title={'Popover Title'}>
              <div className="w-24 border-b-2 border-white pb-8 text-padi-gray-darkest hover:border-padi-blue hover:text-padi-blue">
                {avatar ? <img className="h-24 w-24 rounded-full object-cover" src={avatar} /> : <UserIconSolid />}
              </div>
            </Popover.Button>
            <Popover.Overlay className="fixed inset-0 z-[310] bg-secondary-black opacity-30" />
            <Popover.Panel className="absolute right-0 top-30 z-[310] animate-[appearDropdown_0.15s_ease-in-out] rounded-sm bg-white shadow-400">
              <Popover.Button className="block w-full">
                <div className="w-120 p-14">
                  <Typography className="text-16 text-primary-black hover:underline" onClick={handleLogout}>
                    {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign out' })}
                  </Typography>
                </div>
              </Popover.Button>
            </Popover.Panel>
          </Popover>
        </>
      ) : (
        <div className="w-24 border-b-2 border-white pb-8 text-padi-gray-darkest hover:border-padi-blue hover:text-padi-blue">
          <UserIcon className="w-24" />
        </div>
      )}
    </div>
  );
};
export default React.memo(User);
