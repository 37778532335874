import { Transition } from '@headlessui/react';
import FeedbackIconLayer from 'components/commercetools-ui/atoms/button/feedbackIconLayer';
import Typography from 'components/commercetools-ui/atoms/typography';
import SearchIcon from 'components/icons/search';
import { useAccount } from 'frontastic';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface HeroDestinationSearchProps {
  title: string;
  bgColor: string;
  placeholder: string;
  ctaLabel: string;
}

interface Place {
  title: string;
  formattedAddress: string;
  padiCoursesSearchUrl: string;
}

const HeroDestinationSearch: React.FC<HeroDestinationSearchProps> = ({ title, bgColor, placeholder }) => {
  const input = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const [query, setQuery] = useState('');
  const [focused, setFocused] = useState(false);
  const { getProjectConfig } = useAccount();
  const [places, setPlaces] = useState<Place[]>([]);
  const [isResultsLoading, setIsResultsLoading] = useState(false);
  const [travelEndpoint, setTravelEndpoint] = useState(null);
  const hasRun = useRef(false);

  const textColor = useMemo(() => {
    if (['white', 'padi-gray-lighter', 'padi-gray-light'].includes(bgColor)) {
      return 'padi-gray-darkest';
    }
    return 'white';
  }, [bgColor]);

  const updateQuery = useCallback(
    debounce((value: string) => {
      setQuery(value);
      setFocused(true);
    }, 500),
    [],
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      updateQuery(e.target.value);
    },
    [updateQuery],
  );

  const getCourseSuggest = useCallback(async () => {
    try {
      const res = await fetch(`${travelEndpoint}/api/v2/travel/dsl/courses/autosuggest/?q=${query}`);
      const result = await res.json();
      setPlaces([...result.places, ...result.gplaces]);
      setIsResultsLoading(false);
    } catch (err) {
      console.log('Error getting suggestion', err);
    }
  }, [query]);

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  useEffect(() => {
    if (query) {
      setIsResultsLoading(true);
      getCourseSuggest();
    }
  }, [query]);

  useEffect(() => {
    if (!hasRun.current) {
      getProjectConfig('EXTENSION_PADI_APIS_TRAVEL_DOMAIN').then((res) => {
        setTravelEndpoint(res.setting);
      });
      hasRun.current = true;
    }
  }, [getProjectConfig]);

  useEffect(() => {
    const handleClickEvent = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement && e.target.id !== 'search-input') {
        setFocused(false);
      }
    };
    document.addEventListener('click', handleClickEvent);
    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, []);

  const openLink = (title: string, url: string) => {
    setValue(title);
    window.open(travelEndpoint + url, '_target');
  };

  return (
    <div
      className={`bg-${bgColor} relative flex min-h-300 w-full flex-col items-center justify-center px-24 py-44 text-${textColor}`}
    >
      <Typography as="h2" className="text-center text-30 font-bold md:text-48">
        {title}
      </Typography>
      <form className="relative mt-30 flex w-full flex-col items-center justify-center gap-12 md:flex-row">
        <div className="relative min-h-46 w-full md:min-h-60 md:max-w-400 lg:max-w-[600px]">
          <input
            id="search-input"
            className={`min-h-46 w-full rounded-md text-padi-gray-darkest md:min-h-60 md:max-w-400 md:text-18 lg:max-w-[600px]`}
            ref={input}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholder}
          />
          <SearchIcon className="absolute right-13 top-13 size-20 text-padi-gray-darkest md:right-15 md:top-15 md:size-30" />
          {focused && Boolean(value) && (
            <div className="absolute top-46 z-10 max-h-300 w-full overflow-auto border bg-white  shadow-lg md:top-60 md:max-w-400 lg:max-w-[600px]">
              <Transition
                show={true}
                enter="transition duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {isResultsLoading ? (
                  <div className="bg-white py-10">
                    <div className="relative mb-20">
                      <FeedbackIconLayer loading={true} variant="ghost" />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col  text-sm">
                    <p className="px-15 py-10 pt-14 font-medium text-padi-blue">Nearby</p>
                    {places.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => openLink(item.title, item.padiCoursesSearchUrl)}
                        className="cursor-pointer px-15 py-10  hover:bg-padi-gray-lighter"
                      >
                        <div className="font-medium text-padi-gray-darkest">{item.title}</div>
                        <div className="text-padi-gray-dark">{item.formattedAddress}</div>
                      </div>
                    ))}
                  </div>
                )}
              </Transition>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default HeroDestinationSearch;
