import type { SDKResponse } from '@commercetools/frontend-sdk';
import { useEffect, useState } from 'react';
import { sdk } from 'sdk';
import BreadcrumbsUI from './breadcrumbs-ui';
import type { BreadcrumbItem, FetchPageFolderBreadcrumbResponseBody } from './types';

const parseBreadcrumbData = (response: FetchPageFolderBreadcrumbResponseBody): BreadcrumbItem[] => {
  const { breadcrumbs, userLanguage = 'en_US', currentPageName } = response;
  const breadcrumbsInfo = breadcrumbs?.sort(
    (a, b) => a.ancestorIdsMaterializedPath.length - b.ancestorIdsMaterializedPath.length,
  );
  const paths: string[] = [];
  const breadcrumbItems: BreadcrumbItem[] = breadcrumbsInfo.reduce((items, breadcrumb) => {
    const itemLink = breadcrumb.pathTranslations[userLanguage];

    if (itemLink === '/') {
      // home path = /
      return [
        {
          label: breadcrumb.pageName,
          url: 'https://www.padi.com/',
        },
      ];
    }
    paths.push(breadcrumb.pathTranslations[userLanguage]);

    return [
      ...items,
      {
        label: breadcrumb.pageName,
        url: paths.join('/'),
      },
    ];
  }, [] as BreadcrumbItem[]);

  breadcrumbItems.push({ label: currentPageName, url: location.href });

  return breadcrumbItems;
};

const StaticPageBreadcrumbs: React.FC = () => {
  const [items, setItems] = useState([] as BreadcrumbItem[]);

  useEffect(() => {
    // handle url link = /en-us/test/khue-test/
    const urlPaths = location.pathname.split('/').filter((pathValue) => Boolean(pathValue));
    urlPaths.shift(); // remove the first path (i.e en-us)

    const fetchContent = async () => {
      if (items.length) {
        return;
      }
      const res: SDKResponse<FetchPageFolderBreadcrumbResponseBody> = await sdk.callAction({
        actionName: 'padi-ct-page-folder/fetchPageFolderBreadcrumbs',
        payload: { path: `/${urlPaths.join('/')}` },
      });
      if (res.isError || !res.data) {
        return;
      }
      setItems(parseBreadcrumbData(res.data));
    };
    fetchContent();
  }, [setItems, items]);

  return <BreadcrumbsUI items={items} />;
};

export default StaticPageBreadcrumbs;
