import { FC, useEffect, useState } from 'react';
import FeedbackIconLayer from 'components/commercetools-ui/atoms/button/feedbackIconLayer';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import CartItemsList from './cart-items-list';
import EmptyCart from './empty-cart';
import { CartContentProps } from '../types';

const CartContent: FC<CartContentProps> = ({ className, ...props }) => {
  const { data, isEmpty, totalItems } = useCart();
  const [isLoading, setIsLoading] = useState(true);

  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <h3 className="text-16 md:text-18 lg:text-22">
          {formatCartMessage({ id: 'cart', defaultMessage: 'Cart' })}
          {!isLoading && !isEmpty && (
            <span className="text-secondary-black">
              {`: `} {!isLoading ? totalItems : undefined} {formatCartMessage({ id: 'items', defaultMessage: 'Items' })}
            </span>
          )}
        </h3>
      </div>

      {isLoading ? (
        <div className="relative m-180 flex items-center justify-center">
          <FeedbackIconLayer loading={isLoading} variant="ghost" />
        </div>
      ) : !isEmpty ? (
        <CartItemsList />
      ) : (
        <EmptyCart {...props} />
      )}
    </div>
  );
};

export default CartContent;
