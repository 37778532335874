'use client';
import React from 'react';
import ThankYou, { ThankYouContentProps } from 'components/padi-ui/checkout/components/thank-you';
import { TasticProps } from '../types';

const ThankYouTastic = ({ data }: TasticProps<ThankYouContentProps>) => {
  return <ThankYou {...data} />;
};

export default ThankYouTastic;
