'use client';

import SfmcNewsletterForm, { SfmcNewsletterFormProps } from 'components/padi-ui/form/sfmc-newsletter';
import { TasticProps } from 'frontastic/tastics/types';

const SfmcNewsletterFormTastic = ({ data }: TasticProps<SfmcNewsletterFormProps>) => {
  return <SfmcNewsletterForm {...data} />;
};

export default SfmcNewsletterFormTastic;
