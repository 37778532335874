import { FC, useMemo } from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useTouchDevice from 'helpers/hooks/useTouchDevice';
import { smallMobile, mediumDesktop, desktop, tablet } from 'helpers/utils/screensizes';
import ContentSliderSlide from './slide';
import { ContentSliderProps } from './types';
import Wrapper from '../../../HOC/wrapper';
import Slider from '../../atoms/slider';
import Subtitle from '../../atoms/subtitle';

const ContentSlider: FC<ContentSliderProps> = ({ title, subtitle, slides }) => {
  const [isTabletSize] = useMediaQuery(tablet);
  const [isDesktopSize] = useMediaQuery(desktop);
  const [isMediumDesktopSize] = useMediaQuery(mediumDesktop);
  const { isTouchDevice } = useTouchDevice();

  const isLessThanMediumDesktopSize = useMemo(() => {
    return !isMediumDesktopSize;
  }, [isMediumDesktopSize]);

  const slideWidth = useMemo(() => {
    if (isDesktopSize) return 388;
    else return isTabletSize ? 356 : 244;
  }, [isDesktopSize, isTabletSize]);

  const slidesElement = useMemo(
    () => slides.map((slide) => <ContentSliderSlide key={slide.title} {...slide} />),
    [slides],
  );

  const shouldShowArrows = useMemo(() => {
    return isLessThanMediumDesktopSize || slides.length > 3;
  }, [isLessThanMediumDesktopSize, slides.length]);

  return (
    <Wrapper clearDefaultStyles className="mx-auto mb-24 mt-64 max-w-7xl px-24 lg:px-32">
      <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
        {title && (
          <Typography className="mb-12 text-20 md:text-22 lg:text-28" as="h3">
            {title}
          </Typography>
        )}
        {subtitle && <Subtitle className="mb-24" subtitle={subtitle} />}
        <div className="relative w-full">
          <Slider
            dots={false}
            solidArrows
            arrows={shouldShowArrows}
            allowArrowsOnTouchDevice
            allowTouchMove={isTouchDevice}
            slideWidth={slideWidth}
            nextButtonStyles={{ transform: 'translateY(-150%)', right: '10px' }}
            prevButtonStyles={{ transform: 'translateY(-150%)', left: '10px' }}
            breakpoints={{
              [smallMobile]: {
                spaceBetween: 12,
              },
              [tablet]: {
                spaceBetween: 18,
              },
              [desktop]: {
                spaceBetween: 24,
              },
            }}
          >
            {slidesElement}
          </Slider>
        </div>
      </div>
    </Wrapper>
  );
};
export default ContentSlider;

export * from './types';
