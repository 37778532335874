import Slider from 'components/commercetools-ui/atoms/slider';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useTouchDevice from 'helpers/hooks/useTouchDevice';
import { desktop, smallMobile, tablet } from 'helpers/utils/screensizes';
import React, { memo } from 'react';
import Card, { CardProps } from './card';
import './styles.css';

export interface CardComparisonProps {
  cardInfos: CardProps[];
}

const CardComparison: React.FC<CardComparisonProps> = ({ cardInfos }) => {
  const [isTabletSize] = useMediaQuery(tablet);
  const { isTouchDevice } = useTouchDevice();

  return (
    <div className="card-comparison bg-padi-gray-lighter">
      <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <Slider
          dots={!isTabletSize}
          className="!pb-40"
          solidArrows
          arrows={!isTabletSize}
          allowArrowsOnTouchDevice
          allowTouchMove={isTouchDevice}
          nextButtonStyles={{ transform: 'translateY(-150%)', right: '10px' }}
          prevButtonStyles={{ transform: 'translateY(-150%)', left: '10px' }}
          slidesPerView={isTabletSize ? 2 : 1}
          breakpoints={{
            [smallMobile]: {
              spaceBetween: 0,
            },
            [tablet]: {
              spaceBetween: 18,
            },
            [desktop]: {
              spaceBetween: 32,
            },
          }}
        >
          {cardInfos.map((cardInfo: CardProps) => (
            <Card {...cardInfo} key={cardInfo.title} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default memo(CardComparison);
