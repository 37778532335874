import Accordion, { AccordionItem } from 'components/padi-ui/accordion';
import type { JSX } from 'react';

export interface PadiProductAccordionSectionProps {
  product: Record<string, any>;
  schemaType?: string;
  titleHtag?: string;
  attributeSection: string;
}

const PadiProductAccordionSection: React.FC<PadiProductAccordionSectionProps> = ({
  product,
  attributeSection,
  schemaType,
  titleHtag,
}) => {
  try {
    const TitleHtag = titleHtag ? (titleHtag as keyof JSX.IntrinsicElements) : 'h2';
    const jsonAttribute = product?.variants?.[0].attributes?.[attributeSection];
    const productContent = jsonAttribute && typeof jsonAttribute != 'object' ? JSON.parse(jsonAttribute) : '';
    const isEmpty = !jsonAttribute ? true : false;
    const title = productContent?.title ?? '';
    const items: AccordionItem[] = productContent?.items ?? [];

    // Convert old faq component data to accordion structre.
    const faqs = productContent?.faqs ?? [];
    if (faqs && faqs[0]?.hasOwnProperty('question')) {
      faqs.forEach((item: { question: string; answer: string }) => {
        items.push({
          label: item.question,
          body: item.answer,
        });
      });
    }
    const anchor = title.toLocaleLowerCase().split(' ').join('-');

    return (
      <>
        {!isEmpty && (
          <div>
            {title && (
              <div id={anchor} className="mx-auto max-w-7xl px-24 lg:px-32">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <TitleHtag className="text-2xl font-medium tracking-tight lg:text-3xl lg:font-bold">
                    {title}
                  </TitleHtag>
                  <div></div>
                </div>
              </div>
            )}
            <Accordion items={items} schemaType={schemaType} />
          </div>
        )}
      </>
    );
  } catch (e) {
    console.log('----- e: ', e);
    return <></>;
  }
};

export default PadiProductAccordionSection;
