'use client';

import CrowdRiff, { CrowdRiffProps } from 'components/padi-ui/crowdriff';
import { TasticProps } from 'frontastic/tastics/types';
import { Product } from 'shared/types/product/Product';
import { DataSource } from 'types/datasource';

const getCrowdriffId = (product?: Product, defaultValue = ''): string => {
  const socialMediaInfo = product?.variants?.[0]?.attributes?.['section-10'];
  if (socialMediaInfo) {
    try {
      return JSON.parse(socialMediaInfo)?.['crowdriff-id'] || defaultValue;
    } catch (e) {
      console.error('Error parsing social media section', (e as Error).message);
    }
  }
  return defaultValue;
};

const CrowdRiffTastic = ({ data }: TasticProps<DataSource<{ product: Product }> & CrowdRiffProps>) => {
  const { data: dataSource, ...props } = data;

  const crowdRiffId = getCrowdriffId(dataSource?.dataSource?.product, props.crowdRiffId);

  return <CrowdRiff {...props} crowdRiffId={crowdRiffId} />;
};

export default CrowdRiffTastic;
