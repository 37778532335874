import React from 'react';

type Props = {
  className?: string;
  stroke?: string;
  fill?: string;
};

const Icon: React.FC<Props> = ({ className, stroke, fill }: Props) => (
  <span className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="4" viewBox="0 0 24 4" fill="none">
      <path
        d="M22.7992 3.06337C20.4514 3.55266 18.9863 1.20483 18.9863 1.20483C18.623 1.78243 18.1223 2.26107 17.5289 2.59799C16.9355 2.93492 16.2679 3.11965 15.5858 3.13569C14.9406 3.12372 14.3106 2.93807 13.7621 2.59828C13.2136 2.25849 12.7668 1.77712 12.4688 1.20483C12.0309 1.77442 11.4744 2.24208 10.838 2.57546C10.2015 2.90884 9.50028 3.09999 8.7827 3.13569C8.10493 3.10308 7.44438 2.91128 6.85456 2.57583C6.26473 2.24037 5.76222 1.77069 5.38774 1.20483C5.38774 1.20483 3.50197 3.5921 1.19922 3.10188"
        stroke={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="14" viewBox="0 0 23 14" fill="none">
      <path
        d="M5.5497 3.70358L2.44373 4.79602M0.974191 2.25C0.878012 2.24997 0.784099 2.28081 0.704922 2.33842C0.625745 2.39604 0.56505 2.47771 0.530896 2.57258C0.496742 2.66745 0.490745 2.77104 0.513701 2.86959C0.536657 2.96814 0.587481 3.05699 0.659423 3.12435L6.2257 8.34044C7.18294 9.25069 8.4261 9.754 9.7147 9.75301H13.8968C14.2938 9.75151 14.6848 9.85467 15.0345 10.0531L19.3957 12.5661C19.7269 12.7569 20.1163 12.801 20.4783 12.6889C20.8403 12.5767 21.1452 12.3173 21.326 11.9679C21.5068 11.6185 21.5487 11.2076 21.4423 10.8256C21.336 10.4436 21.0902 10.1218 20.7591 9.93108L16.3931 7.42307C15.627 6.98334 14.7689 6.75259 13.8968 6.75181H9.7147C9.12905 6.75216 8.56402 6.52373 8.12853 6.11055L4.52576 2.7522C4.17885 2.42938 3.73208 2.25148 3.26953 2.252L0.974191 2.25ZM15.173 3.5005C15.173 3.83216 15.0481 4.15023 14.8259 4.38474C14.6036 4.61925 14.3022 4.751 13.9879 4.751H9.68252C9.55679 4.751 9.43622 4.6983 9.34731 4.6045C9.25841 4.51069 9.20847 4.38346 9.20847 4.2508V2.7502C9.20847 2.61754 9.25841 2.49031 9.34731 2.39651C9.43622 2.3027 9.55679 2.25 9.68252 2.25H13.9879C14.3022 2.25 14.6036 2.38175 14.8259 2.61626C15.0481 2.85078 15.173 3.16885 15.173 3.5005Z"
        stroke={stroke}
        fill={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="19.25" cy="3.75" r="2.5" stroke={stroke} fill={fill} />
    </svg>
  </span>
);

export default Icon;
