'use client';

import HeaderStar, { HeaderStarProps } from 'components/padi-ui/header-star';
import { HeaderSectionProps } from 'components/padi-ui/header-section';

import { TasticProps } from 'frontastic/tastics/types';

const HeaderStarTastic = ({ data }: TasticProps<Omit<HeaderStarProps, 'headerSectionProps'> & HeaderSectionProps>) => {
  const { rating, ratingDescription, textGuide, ...headerSectionProps } = data;
  return (
    <HeaderStar
      rating={rating}
      ratingDescription={ratingDescription}
      textGuide={textGuide}
      headerSectionProps={headerSectionProps}
    />
  );
};

export default HeaderStarTastic;
