import React, { memo } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import { Reference } from 'types/reference';
import { classnames } from 'helpers/utils/classnames';

export interface MenuTab {
  title: string;
  link: Reference;
  isActive?: boolean;
  dataAnalytics?: string;
}
export interface MenuTabsProps {
  menuTabs: MenuTab[];
}

const MenuTabs: React.FC<MenuTabsProps> = ({ menuTabs }) => {
  return (
    <div className="prose mx-auto max-w-7xl overflow-x-auto px-24 pb-12 pt-24 md:px-24 md:py-12 lg:rounded-md lg:p-36 lg:px-32">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex w-full" aria-label="MenuTabs">
          {menuTabs.map((tab) => (
            <Link
              key={tab.title}
              link={tab.isActive ? location.href : tab.link}
              data-analytics={tab.dataAnalytics}
              className={classnames(
                'flex-1 whitespace-nowrap border-b-2 px-20 py-4 text-center text-16 font-semibold no-underline ',
                tab.isActive
                  ? 'border-padi-gray-darkest bg-padi-gray-lightest text-padi-gray-darkest hover:text-padi-gray-darkest'
                  : 'border-transparent text-padi-gray-darker hover:border-gray-300 hover:text-padi-blue-darker',
              )}
            >
              <span>{tab.title}</span>
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default memo(MenuTabs);
