import { Transition } from '@headlessui/react';
import { default as YoutubePlay } from 'components/icons/youtube-play';
import Image, { ImageProps } from 'frontastic/lib/image';
import useClassNames from 'helpers/hooks/useClassNames';
import dynamic from 'next/dynamic';
import { memo, useState } from 'react';

const Video = dynamic(() => import('../video'), { ssr: false });

export interface VideoPlayerProps {
  youTubeLink?: string;
  isLoopVideo: boolean;
  isAudio: boolean;
  isAutoPlay: boolean;
  isFullWidth: boolean;
  posterImage?: ImageProps;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  youTubeLink,
  isLoopVideo,
  isAudio,
  isAutoPlay,
  isFullWidth,
  posterImage,
}) => {
  const [showVideo, setShowVideo] = useState(false);

  const handleShowVideo = () => {
    setShowVideo(true);
  };
  const wrapperClass = useClassNames(['relative mx-auto px-24 ', isFullWidth ? 'max-w-7xl' : 'max-w-3xl']);
  return (
    <div className={wrapperClass}>
      <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
        <Transition
          show={!showVideo}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 blur-sm"
          enterTo="opacity-100 blur-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 blur-0"
          leaveTo="opacity-0 blur-sm"
        >
          <div className="absolute w-full" style={{ paddingBottom: '56.25%' }}>
            {posterImage?.media && (
              <Image
                {...posterImage}
                priority
                loading="eager"
                alt=""
                fill
                style={{ objectFit: 'cover' }}
                className="absolute left-0 top-0 h-full w-full brightness-75"
              />
            )}
            <div
              className="absolute inset-0 m-auto inline-flex h-100 w-100 items-center justify-center hover:cursor-pointer"
              onClick={handleShowVideo}
            >
              <YoutubePlay />
            </div>
          </div>
        </Transition>

        {showVideo && youTubeLink && (
          <Transition
            show={showVideo}
            enterFrom="transform origin-left scale-x-0"
            enterTo="transform origin-left scale-x-100"
            leave="transition ease-in duration-100"
            leaveFrom="transform origin-left scale-x-150"
            leaveTo="transform origin-left scale-x-0"
          >
            <div className="absolute left-0 top-0 h-auto w-full">
              <Video
                youTubeLink={youTubeLink}
                isLoopVideo={isLoopVideo}
                isAudio={isAudio}
                isAutoPlay={showVideo ?? isAutoPlay}
              />
            </div>
          </Transition>
        )}
      </div>
    </div>
  );
};

export default memo(VideoPlayer);
