import DynamicPageBreadcrumbs from './dynamic-page-breadcrumbs';
import StaticPageBreadcrumbs from './static-page-breadcrumbs';
import { BreadcrumbItem } from './types';
import { Product } from 'shared/types/product/Product';

export interface BreadcrumbsProps {
  isStaticPage: boolean;
  breadcrumbItems: BreadcrumbItem[];
  product?: Product;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ isStaticPage, breadcrumbItems, product }) => {
  if (isStaticPage) {
    return <StaticPageBreadcrumbs />;
  }
  return <DynamicPageBreadcrumbs items={breadcrumbItems} product={product} />;
};

export default Breadcrumbs;
