import { useEffect, useMemo, useRef, useState } from 'react';

export interface VideoProps {
  youTubeLink: string;
  isLoopVideo: boolean;
  isAudio: boolean;
  isAutoPlay: boolean;
}

const Video: React.FC<VideoProps> = ({ youTubeLink, isLoopVideo, isAudio, isAutoPlay }) => {
  const getVideoId = (url: string) => {
    const embedMatch = url.match(/\/embed\/([^?]+)/);
    const shortMatch = url.match(/youtu\.be\/([^?]+)/);

    if (embedMatch) {
      return embedMatch[1];
    } else if (shortMatch) {
      return shortMatch[1];
    }
    return null;
  };

  const videoId = useMemo(() => getVideoId(youTubeLink), [youTubeLink]);
  const playerRef = useRef<HTMLDivElement>(null);
  const [player, setPlayer] = useState<any>(null);

  useEffect(() => {
    // Check if the script is already added
    const existingScript = document.querySelector('script[src="https://www.youtube.com/iframe_api"]');
    if (!existingScript) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.head.appendChild(tag);
    }

    // Initialize player when the YouTube API is ready
    const initializePlayer = () => {
      new (window as any).YT.Player(`player-${videoId}`, {
        videoId,
        playerVars: {
          autoplay: isAutoPlay ?? 0, // Auto-play the video on load
          controls: 1, // Show pause/play buttons in player
          showinfo: 0, // Hide the video title
          modestbranding: 1, // Hide the Youtube Logo
          loop: isLoopVideo ? 1 : 0, // Run the video in a loop
          autohide: 1, // Hide video controls when playing,
          mute: isAudio ? 0 : 1, // Mute/unmute based on state
        },
        events: {
          onReady: (event: any) => {
            setPlayer(event.target);
          },
        },
      });
    };

    // Check if the API is already loaded
    if ((window as any).YT && (window as any).YT.Player) {
      initializePlayer();
    } else {
      // Add to the onYouTubePlayerAPIReady callback queue if the API is still loading
      const previousCallback = (window as any).onYouTubePlayerAPIReady;
      (window as any).onYouTubePlayerAPIReady = () => {
        if (previousCallback) {
          previousCallback();
        }
        initializePlayer();
      };
    }
  }, [videoId]);

  useEffect(() => {
    if (!isAutoPlay) {
      return;
    }
    const handleScroll = () => {
      if (playerRef.current) {
        const videoElement = playerRef.current;
        const heightOfVideo = videoElement.offsetHeight;
        const topOfVideo = videoElement.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (((windowHeight - heightOfVideo) / 2 > topOfVideo || topOfVideo < 60) && topOfVideo > -heightOfVideo) {
          if (player) {
            player.playVideo();
            videoElement.style.opacity = '1';
          }
        } else {
          if (player) {
            player.pauseVideo();
            videoElement.style.opacity = '0.6';
          }
        }
      }
    };

    // Add scroll and load event listeners
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('load', handleScroll);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('load', handleScroll);
    };
  }, [player, isAutoPlay]);

  if (!videoId) {
    return <></>;
  }

  return (
    <div className={`aspect-h-9 aspect-w-16 ${videoId}`} ref={playerRef}>
      <div id={`player-${videoId}`}></div>
    </div>
  );
};

export default Video;
