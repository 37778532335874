import React, { memo } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import DivesIcon from 'components/icons/dives';
import PoolDivesIcon from 'components/icons/pool-dives';
import RatingIcon from '../rating';
import Image, { ImageProps } from 'frontastic/lib/image';
import { Reference } from 'types/reference';

export interface CardProps {
  image: ImageProps;
  title: string;
  description: string; // markdown content
  eLearningRequired: boolean;
  ctaButtonText: string;
  ctaButtonUrl: Reference;
  ctaButtonDataAnalytic?: string;
  takeCourseTitle: string;
  takeCourseDetails: { description: string }[];
  whatToExpectTitle: string;
  confinedWaterDivesText: string;
  openWaterDivesText: string;
  afterCourseTitle: string;
  afterCourseDetails: string;
  rating: number;
  ratingDescription: string;
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  image,
  eLearningRequired,
  ctaButtonText,
  ctaButtonUrl,
  ctaButtonDataAnalytic,
  takeCourseTitle,
  takeCourseDetails,
  whatToExpectTitle,
  confinedWaterDivesText,
  openWaterDivesText,
  afterCourseTitle,
  afterCourseDetails,
  rating,
  ratingDescription,
}) => {
  return (
    <div className="flex-1 bg-white shadow-md">
      <Image {...image} className="p-16 pb-0" />
      <div className="p-40">
        <RatingIcon rating={rating} ratingDescription={ratingDescription} className="mb-16" />
        <h4 className="text-padi-text-darkest text-16 font-bold md:text-20">{title}</h4>
        <Markdown
          markdown={description}
          className="prose text-16 text-padi-gray-darker prose-strong:text-padi-gray-darkest prose-ul:list-disc prose-li:ml-4"
        />
        <div className="pt-30 text-padi-blue-darkest">
          <h6 className="text-16 text-padi-gray-darkest">{takeCourseTitle}</h6>
          {takeCourseDetails.map((item, index) => (
            <div className="flex flex-row gap-16 pt-20" key={index}>
              <span className="h-24 w-24">
                <CheckCircleIcon className="h-24 w-24 text-padi-blue" />
              </span>
              <p className="text-14">{item.description}</p>
            </div>
          ))}
        </div>
        <div className="pt-30 text-14 text-padi-blue-darkest">
          <h6 className="text-16 text-padi-gray-darkest">{whatToExpectTitle}</h6>
          {eLearningRequired && (
            <div className="flex flex-row gap-16 pt-16">
              <span className="h-24 w-24">
                <BookOpenIcon className="h-24 w-24" />
              </span>
              <p className="text-14">PADI eLearning</p>
            </div>
          )}
          <div className="flex flex-row gap-16 pt-20">
            <span className="h-24 w-24">
              <PoolDivesIcon className="h-24 w-24 text-padi-blue" />
            </span>
            <p className="text-14">{confinedWaterDivesText}</p>
          </div>
          <div className="flex flex-row gap-16 pt-20">
            <DivesIcon className="h-24 w-24 text-padi-blue" stroke="#172554" />
            <p className="text-14">{openWaterDivesText}</p>
          </div>
        </div>
        <div className="pt-30 text-padi-blue-darkest">
          <h6 className="text-16 text-padi-gray-darkest">{afterCourseTitle}</h6>
          <Markdown
            markdown={afterCourseDetails}
            className="prose text-14 text-padi-gray-darkest prose-strong:text-padi-gray-darkest prose-ul:list-disc prose-li:ml-4"
          />
        </div>
        <Link padi-analytics={ctaButtonDataAnalytic} link={ctaButtonUrl}>
          <Button variant="primary" size="full" className="mt-24 bg-padi-blue px-20 py-12 text-16">
            {ctaButtonText}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default memo(Card);
