import Markdown from 'components/commercetools-ui/organisms/markdown';
import Image, { ImageProps } from 'frontastic/lib/image';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import { useMemo, type JSX } from 'react';

export interface HeaderSectionProps {
  eyebrow: string;
  title: string;
  titleHtag?: string;
  titleSize:
    | 'text-xl'
    | 'text-2xl'
    | 'text-3xl'
    | 'text-4xl'
    | 'text-5xl'
    | 'text-6xl'
    | 'text-7xl'
    | 'text-8xl'
    | 'text-9xl';
  body: string;
  bgColor: string;
  textAlign: 'center' | 'left' | 'right';
  image?: ImageProps;
  imagePosition?: 'center' | 'left' | 'right';
  className?: string;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
  eyebrow,
  title,
  titleHtag,
  titleSize,
  body,
  bgColor,
  textAlign = 'left',
  image,
  imagePosition = 'left',
  className,
}) => {
  const isHtml = isStringHtml(body);

  // Map the titleSize prop to static class names for both mobile and lg
  const titleSizeVariants = {
    'text-xl': 'text-lg lg:text-xl',
    'text-2xl': 'text-xl lg:text-2xl',
    'text-3xl': 'text-2xl lg:text-3xl',
    'text-4xl': 'text-3xl lg:text-4xl',
    'text-5xl': 'text-4xl lg:text-5xl',
    'text-6xl': 'text-5xl lg:text-6xl',
    'text-7xl': 'text-6xl lg:text-7xl',
    'text-8xl': 'text-7xl lg:text-8xl',
    'text-9xl': 'text-8xl lg:text-9xl',
  };

  const classNameTitle = `mt-2 font-bold tracking-tight text-gray-900 ${titleSizeVariants[titleSize]}`;
  const classNameMain = `bg-${bgColor} text-${textAlign} ${className || ' py-24 sm:py-32'}`;
  const TitleHtag = titleHtag ? (titleHtag as keyof JSX.IntrinsicElements) : 'h2';

  let classNameContainer1;
  let classNameContainer2;

  switch (textAlign) {
    case 'center':
      classNameContainer1 = `mx-auto max-w-2xl px-24 lg:px-32`;
      classNameContainer2 = `w-full lg:mx-0`;
      break;
    case 'right':
      classNameContainer1 = `mx-auto max-w-7xl px-24 lg:px-32`;
      classNameContainer2 = `mx-auto max-w-7xl lg:mx-0`;
      break;
    case 'left':
    default:
      classNameContainer1 = `mx-auto max-w-7xl px-24 lg:px-32`;
      classNameContainer2 = `mx-auto max-w-2xl lg:mx-0`;
  }

  const imageClassName = useMemo(() => {
    const styleMapping = {
      left: 'justify-start',
      center: 'justify-center',
      right: 'justify-end',
    };
    return styleMapping[imagePosition];
  }, [imagePosition]);

  const anchor = title.toLocaleLowerCase().split(' ').join('-');

  return (
    <>
      {image?.media && (
        <div className={` py-20 bg-${bgColor}`}>
          <div className={`mx-auto flex max-w-7xl px-24 lg:px-32 ${imageClassName}`}>
            <Image {...image} alt="logo" style={{ objectFit: 'cover' }} className={`max-h-60 w-auto`} />
          </div>
        </div>
      )}
      {(eyebrow || title || body) && (
        <div className={classNameMain} id={anchor}>
          <div className={classNameContainer1}>
            <div className={classNameContainer2}>
              <p className="text-base font-semibold leading-7 text-padi-blue-darker">{eyebrow}</p>
              <TitleHtag className={classNameTitle}>{title}</TitleHtag>
              <div className="mt-6 text-lg leading-8 text-gray-600">
                <div className="prose max-w-full" style={{ textAlign }}>
                  {body && (isHtml ? <div dangerouslySetInnerHTML={{ __html: body }} /> : <Markdown markdown={body} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderSection;
