'use client';

import { Product } from 'shared/types/product/Product';
import ProductSfmcNewsletter, { ProductSfmcNewsletterProps } from 'components/padi-product/form/sfmc-newsletter';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

const ProductSfmcNewsletterTastic = ({
  data,
}: TasticProps<DataSource<{ product: Product }> & ProductSfmcNewsletterProps>) => {
  if (!data?.data?.dataSource?.product) {
    console.warn('ProductSfmcNewsletterTastic - No product data found!');
    return null;
  }

  return (
    <ProductSfmcNewsletter
      product={data?.data?.dataSource.product}
      attributeSection={data?.attributeSection}
      title={data?.title}
      titleHtag={data?.titleHtag}
      body={data?.body}
      submitButtonText={data?.submitButtonText}
      legalDisclaimer={data?.legalDisclaimer}
      confirmationMessage={data?.confirmationMessage}
      details={data?.details}
      dataAnalytics={data?.dataAnalytics}
    />
  );
};

export default ProductSfmcNewsletterTastic;
